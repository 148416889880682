import {
  SubscriptionOptions,
  SubscriptionCallback,
  SubscriptionError,
  SubscriptionStream,
  ExchangeSocketsClient,
} from '@shared/services/sockets/index';
import InternalError from '@shared/errors/InternalError';
import SocketClient from '@shared/services/sockets/socketClient';
import {
  KrakenPair,
  KrakenPrice,
  getPair,
} from '@shared/domain/kraken';
import createThrottle from '@shared/utils/throttle';

export enum SendEvent {
  Subscribe = 'subscribe',
  Unsubscribe = 'unsubscribe',
}

export enum Channel {
  Ticker = 'ticker',
}

export enum ReceiveEvent {
  Update = 'update',
  Subscribe = 'subscribe',
  Unsubscribe = 'unsubscribe',
}

export type SubscribeEventMessage = {
  method: SendEvent.Subscribe,
  channel: undefined,
  params: {
    channel: Channel,
    symbol: KrakenPair[]
  }

};

export type UnsubscribeEventMessage = {
  method: SendEvent.Unsubscribe,
  channel: undefined,
  params: {
    channel: Channel,
    symbol: KrakenPair[]
  }
};

export type SubscribeMessage = {
  method: ReceiveEvent.Subscribe;
  channel: undefined,
};

export type UnsubscribeMessage = {
  method: ReceiveEvent.Unsubscribe;
  channel: undefined,
};

export type TickerMessage = {
  method: undefined,
  channel: Channel.Ticker;
  type: ReceiveEvent.Update;
  data: [{
    symbol: KrakenPair,
    last: KrakenPrice
  }]
};

export type Message = (
  | TickerMessage
  | SubscribeMessage
  | UnsubscribeMessage
  | SubscribeEventMessage
  | UnsubscribeEventMessage
);

export class KrakenSpotClient implements ExchangeSocketsClient {
  protected socket: SocketClient<Message>;

  private intervals: { [key: string]: Function } = {};

  constructor({ url = 'wss://ws.kraken.com/v2' } = {}) {
    this.socket = new SocketClient<Message>({
      url,
      handleMessage: this.handleMessage.bind(this),
    });
  }

  private handleTicker(message: Message) {
    if (message.channel !== Channel.Ticker) return;

    const update = message.data[0];
    const key = Channel.Ticker + update.symbol;

    if (!this.intervals[key]) {
      this.intervals[key] = createThrottle((price: string) => {
        this.socket.subscriptions[key]?.callbacks.map(
          (subscription) => subscription(Number(price)),
        );
      }, 1000);
    }

    this.intervals[key](update.last);
  }

  private handleMessage(message: Message) {
    if (message?.method === ReceiveEvent.Subscribe) {
      // Subscription success messages
      return;
    }
    if (message?.method === ReceiveEvent.Unsubscribe) {
      // Unsubscription success messages
      return;
    }

    switch (message.channel) {
      case Channel.Ticker:
        this.handleTicker(message);
        break;
      default:
    }
  }

  public subscribe(
    stream: SubscriptionStream,
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
    onError?: SubscriptionError,
  ) {
    switch (stream) {
      case SubscriptionStream.Ticker:
        this.subscribeToTicker(callback, options, onError);
        break;
      default:
        throw new InternalError('Unknown stream');
    }
  }

  public unsubscribe(
    stream: SubscriptionStream,
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
  ) {
    switch (stream) {
      case SubscriptionStream.Ticker:
        this.unsubscribeFromTicker(callback, options);
        break;
      default:
        throw new InternalError('Unknown stream');
    }
  }

  private subscribeToTicker(
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
    onError?: SubscriptionError,
  ) {
    const { base, counter } = options || {};

    if (!base || !counter) throw new InternalError('base and counter are required');

    const krakenPair = getPair(base, counter);
    const key = `${Channel.Ticker}${krakenPair}`;

    this.socket.subscribe(
      key,
      {
        method: SendEvent.Subscribe,
        channel: undefined,
        params: {
          channel: Channel.Ticker,
          symbol: [krakenPair],
        },
      },
      callback,
      onError,
    );
  }

  private unsubscribeFromTicker(
    callback: SubscriptionCallback,
    options?: SubscriptionOptions,
  ) {
    const { base, counter } = options || {};

    if (!base || !counter) throw new InternalError('base and counter are required');

    const krakenPair = getPair(base, counter);
    const key = `${Channel.Ticker}${krakenPair}`;

    this.socket.unsubscribe(
      key,
      {
        method: SendEvent.Unsubscribe,
        channel: undefined,
        params: {
          channel: Channel.Ticker,
          symbol: [krakenPair],
        },
      },
      callback,
    );
  }
}
