import { useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { OrderBookStorageService } from '@shared/application/ports';
import { useSocketsState } from '@shared/services/context/sockets';
import { SubscriptionStream } from '@shared/services/sockets';
import { calculateBookTotal } from '@shared/utils/orderBook';
import { Book, MiniBook } from '@shared/domain/orderBook';
import { ExchangeAccount } from '@shared/domain/account';
import { getOrderBook as getOrderBookBinanceSpot } from '@shared/services/api/binanceSpot';
import { getOrderBook as getOrderBookBinanceUSDM } from '@shared/services/api/binanceUSDM';
import { getOrderBook as getOrderBookCoinbasePro } from '@shared/services/api/coinbasePro';
import { getOrderBook as getOrderBookGateioSpot } from '@shared/services/api/gateioSpot';
import { fetchOrderBook as getOrderBookBitfinexSpot } from '@shared/adapters/client/bitfinexClientAdapters';
import { getOrderBook as getOrderBookKrakenSpot } from '@shared/services/api/krakenSpot';

import InternalError from '@shared/errors/InternalError';

export const getApiByExchange = (exchangeAccount: ExchangeAccount) => {
  switch (exchangeAccount) {
    case ExchangeAccount.BinanceSpot:
      return getOrderBookBinanceSpot;
    case ExchangeAccount.BinanceUSDM:
      return getOrderBookBinanceUSDM;
    case ExchangeAccount.CoinbaseSpot:
    case ExchangeAccount.CoinbasePrimeSpot:
      return getOrderBookCoinbasePro;
    case ExchangeAccount.GateioSpot:
      return getOrderBookGateioSpot;
    case ExchangeAccount.BitfinexSpot:
      return getOrderBookBitfinexSpot;
    case ExchangeAccount.KrakenSpot:
      return getOrderBookKrakenSpot;
    default:
      throw new InternalError('Unknown exchange');
  }
};

export function useOrderBookSubscription({
  base,
  counter,
  tick = 1000,
  exchangeAccount,
  disabled,
}: {
  base?: Currency,
  counter?: Currency,
  tick: number,
  exchangeAccount?: ExchangeAccount,
  disabled?: boolean,
}) {
  const socketStorage = useSocketsState();
  const queryClient = useQueryClient();

  useEffect(() => {
    const queryKey = ['order-book', exchangeAccount, base, counter];

    if (!exchangeAccount || !base || !counter || disabled) return () => {};

    const callback = (book: Book) => queryClient.setQueriesData(queryKey, () => book);
    const options = { base, counter, tick };
    const calculateBookCallback = (miniBook: MiniBook) => callback(calculateBookTotal(miniBook));
    const onError = (error: any) => queryClient.setQueriesData(queryKey, () => error);

    socketStorage[exchangeAccount]?.subscribe(
      SubscriptionStream.OrderBook,
      calculateBookCallback,
      options,
      onError,
    );

    return () => {
      socketStorage[exchangeAccount]?.unsubscribe(
        SubscriptionStream.OrderBook,
        calculateBookCallback,
        options,
      );
      queryClient.removeQueries(queryKey);
    };
  }, [base, counter, tick, exchangeAccount, disabled]);
}

export function useOrderBookStore({
  base,
  counter,
  exchangeAccount,
  disabled,
  onError,
}: {
  base?: Currency,
  counter?: Currency,
  exchangeAccount?: ExchangeAccount,
  disabled?: boolean,
  onError?: (err: unknown) => void
}): OrderBookStorageService {
  const getOrderBook = exchangeAccount && getApiByExchange(exchangeAccount);
  const options = {
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!getOrderBook && !!base && !!counter && !!exchangeAccount && !disabled,
    staleTime: Infinity,
    select: (value: any) => {
      if (value instanceof Error) throw value;
      return value;
    },
    onError,
  };

  if (!onError) delete options.onError;

  const {
    isLoading,
    error,
    data,
  } = useQuery(
    ['order-book', exchangeAccount, base, counter],
    async () => {
      if (!getOrderBook || !exchangeAccount || !base || !counter) throw new InternalError('Empty params do not allowed');

      const { asks, bids } = await getOrderBook({
        base,
        counter,
      });

      return calculateBookTotal({
        asks,
        bids,
      });
    },
    options,
  );

  return {
    isLoading,
    error: error as Error | undefined,
    orderBook: data,
  };
}
