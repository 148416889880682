import { useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { SubscriptionStream } from '@shared/services/sockets';
import InternalError from '@shared/errors/InternalError';
import { ExchangeAccount } from '@shared/domain/account';
import { useSocketsState } from '@shared/services/context/sockets';
import { CurrencyTickerClientService } from '@shared/application/ports';
import { getTicker as getTickerBinanceSpot } from '@shared/services/api/binanceSpot';
import { getTicker as getTickerBinanceUSDM } from '@shared/services/api/binanceUSDM';
import { getTicker as getTickerCoinbasePro } from '@shared/services/api/coinbasePro';
import { getTicker as getTickerGateioSpot } from '@shared/services/api/gateioSpot';
import { fetchTicker as getTickerBitfinexSpot } from '@shared/adapters/client/bitfinexClientAdapters';
import { getTicker as getTickerKrakenSpot } from '@shared/services/api/krakenSpot';

export const getApiByExchange = (exchangeAccount: ExchangeAccount) => {
  switch (exchangeAccount) {
    case ExchangeAccount.BinanceSpot:
      return getTickerBinanceSpot;
    case ExchangeAccount.BinanceUSDM:
      return getTickerBinanceUSDM;
    case ExchangeAccount.CoinbaseSpot:
    case ExchangeAccount.CoinbasePrimeSpot:
      return getTickerCoinbasePro;
    case ExchangeAccount.GateioSpot:
      return getTickerGateioSpot;
    case ExchangeAccount.BitfinexSpot:
      return getTickerBitfinexSpot;
    case ExchangeAccount.KrakenSpot:
      return getTickerKrakenSpot;
    case ExchangeAccount.KrakenEarn:
      return getTickerKrakenSpot;
    default:
      throw new InternalError('Unknown exchange');
  }
};

export function useCurrencyTickerSubscription({
  base,
  counter,
  tick,
  exchangeAccount,
  disabled,
}: {
  base?: Currency,
  counter?: Currency,
  tick: number,
  exchangeAccount?: ExchangeAccount,
  disabled?: boolean,
}) {
  const socketStorage = useSocketsState();
  const queryClient = useQueryClient();

  useEffect(() => {
    const queryKey = ['ticker', exchangeAccount, base, counter];

    if (!exchangeAccount || !base || !counter || disabled) return () => {};

    const callback = (price: number) => queryClient.setQueriesData(queryKey, () => price);
    const onError = (error: any) => queryClient.setQueriesData(queryKey, () => error);
    const options = { base, counter, tick };

    socketStorage[exchangeAccount]?.subscribe(
      SubscriptionStream.Ticker,
      callback,
      options,
      onError,
    );

    return () => {
      socketStorage[exchangeAccount]?.unsubscribe(SubscriptionStream.Ticker, callback, options);
      queryClient.removeQueries(queryKey);
    };
  }, [base, counter, tick, exchangeAccount, disabled]);
}

export function useCurrencyTickerClient({
  base,
  counter,
  exchangeAccount,
  disabled,
  onError,
}: {
  base?: Currency,
  counter?: Currency,
  exchangeAccount?: ExchangeAccount,
  disabled?: boolean,
  onError?: (err: unknown) => void
}): CurrencyTickerClientService {
  const getTicker = exchangeAccount && getApiByExchange(exchangeAccount);
  const options = {
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!getTicker && !!base && !!counter && !!exchangeAccount && !disabled,
    staleTime: Infinity,
    select: (value: any) => {
      if (value instanceof Error) throw value;
      return value;
    },
    onError,
  };

  if (!onError) delete options.onError;

  const {
    isLoading,
    error,
    data,
  } = useQuery(
    ['ticker', exchangeAccount, base, counter],
    async () => {
      if (!getTicker || !exchangeAccount || !base || !counter) throw new InternalError('Empty params do not allowed');
      const { price } = await getTicker(base, counter);
      return price ? Number(price) : undefined;
    },
    options,
  );

  return {
    isLoading,
    error: error as Error | undefined,
    ticker: data,
  };
}
