import * as React from 'react';
import { createContext } from 'react';
import InternalError from '@shared/errors/InternalError';
import { BinanceSpotClient } from '@shared/services/sockets/binanceSpotClient';
import { BinanceUSDMClient } from '@shared/services/sockets/binanceUSDMClient';
import { CoinbaseProClient } from '@shared/services/sockets/coinbaseProClient';
import { GateioSpotClient } from '@shared/services/sockets/gateioSpotClient';
import { BitfinexSpotClient } from '@shared/services/sockets/bitfinexSpotClient';
import { KrakenSpotClient } from '@shared/services/sockets/krakenSpotClient';
import { ExchangeAccount } from '@shared/domain/account';

export type SocketsContextValues = {
  [ExchangeAccount.BinanceSpot]: BinanceSpotClient,
  [ExchangeAccount.BinanceUSDM]: BinanceUSDMClient,
  [ExchangeAccount.BitfinexSpot]: BitfinexSpotClient,
  [ExchangeAccount.BitfinexMargin]: undefined,
  [ExchangeAccount.CoinbaseSpot]: CoinbaseProClient,
  [ExchangeAccount.CoinbasePrimeSpot]: CoinbaseProClient,
  [ExchangeAccount.GateioSpot]: GateioSpotClient,
  [ExchangeAccount.KrakenSpot]: KrakenSpotClient,
  [ExchangeAccount.KrakenEarn]: KrakenSpotClient,
};

export const SocketsContext = createContext<SocketsContextValues>({
  [ExchangeAccount.BinanceSpot]: new BinanceSpotClient(),
  [ExchangeAccount.BinanceUSDM]: new BinanceUSDMClient(),
  [ExchangeAccount.BitfinexSpot]: new BitfinexSpotClient(),
  [ExchangeAccount.BitfinexMargin]: undefined,
  [ExchangeAccount.CoinbaseSpot]: new CoinbaseProClient(),
  [ExchangeAccount.CoinbasePrimeSpot]: new CoinbaseProClient(),
  [ExchangeAccount.GateioSpot]: new GateioSpotClient(),
  [ExchangeAccount.KrakenSpot]: new KrakenSpotClient(),
  [ExchangeAccount.KrakenEarn]: new KrakenSpotClient(),
});

export function useSocketsState() {
  const context = React.useContext(SocketsContext);
  if (!context) {
    throw new InternalError('useSocketsState must be used within a SocketsStateProvider');
  }
  return context;
}
