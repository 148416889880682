import { MiniBook } from '@shared/domain/orderBook';
import api from '@shared/services/api';
import { getPair } from '@shared/domain/kraken';

export type TickerResponse = {
  symbol: CurrencyPair,
  price: CurrencyAmount,
};

export const getTicker = (
  base: Currency,
  counter: Currency,
): Promise<TickerResponse> => (
  api.get(`v1/requests/cors-proxy?url=${encodeURIComponent(`https://api.kraken.com/0/public/Ticker/?pair=${getPair(base, counter)}`)}`)
    .then(({ result, error }) => {
      if (error?.length) throw new Error(error[0]);
      return {
        symbol: getPair(base, counter),
        price: result?.[getPair(base, counter)]?.c?.[0],
      };
    })
);

export const getOrderBook = ({
  base,
  counter,
}: {
  base: Currency,
  counter: Currency,
}): Promise<MiniBook> => (
  api.get(`v1/requests/cors-proxy?url=${encodeURIComponent(`https://api.kraken.com/0/public/Depth/?pair=${getPair(base, counter)}&count=20`)}`)
);
